import React from "react";

const Card: React.FC<{ card: any }> = ({ card }) => {
    const keys = [];
    for (const key in card) {
        keys.push(key);
    }
    const index = keys[0]?.split("_")[3];

    return (
        <li className="about-info__item">
            <div className="about-info__text-wrapper">
                <h3 className="about-info__text-title">
                    {card[`vertical_card_header_${index}`]?.value}
                </h3>
                {card[`vertical_card_text_${index}`]?.type === "text" ? (
                    <p
                        className="about-info__text"
                        dangerouslySetInnerHTML={{
                            __html:
                                card[`vertical_card_text_${index}`]?.value ||
                                "",
                        }}
                    ></p>
                ) : (
                    <p className="about-info__text">
                        {card[`vertical_card_text_${index}`]?.value}
                    </p>
                )}
                <a
                    className="about-info__link"
                    href={card[`vertical_card_link_${index}`]?.value?.url}
                    target="_blank"
                >
                    {card[`vertical_card_link_${index}`]?.value?.title}
                </a>
            </div>
            <div className="about-info__img">
                <img
                    src={card[`vertical_card_image_${index}`]?.value}
                    alt={card[`vertical_card_image_${index}`]?.title}
                />
            </div>
        </li>
    );
};

export default Card;
