import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../components/Avatar";
import Score from "../../../components/Score";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { Customer, Executor } from "../../../models/ordersModel";
import { chooseDeclension, getUrlTransliteName } from "../../../untils";
import { TranslationObject } from "../../../models/translationModal";
import { transliterate } from 'transliteration';

interface Props {
    user?: Customer | Executor;
    isDeal: boolean;
    isDone: boolean;
    isSpecialist: boolean;
    entities?: TranslationObject;
}

const UserCard: React.FC<Props> = ({ user, isDeal, isDone, isSpecialist, entities }) => {
    const hide = isSpecialist ? !user : !user || !(isDeal || isDone);
    const currentLanguage = getCurrentLanguage();
    const isRussian = currentLanguage === 'ru';
    const processedName = user?.name ? transliterate(user.name.trim().replace(/\s+/g, '-')).toLowerCase() : 'noname';

    if (hide) {
        return null;
    }
    const linkToUser = `/${currentLanguage}/specialist/${processedName}-${user?.id}`;
    const linkToReviews = isSpecialist
        ? `/${currentLanguage}/customer/${user?.id}/reviews`
        : `/${currentLanguage}/specialist/${processedName}-${user?.id}/reviews`;

    const dealCountsText = chooseDeclension(user?.deals_count, entities?.specialist_details_deals?.value || '', isRussian)
    const reviewCountsText = chooseDeclension(user?.reviews_count, entities?.specialist_details_reviews_count?.value || '', isRussian)

    return (<div className="profile-mini-card">
        <div className="profile-mini-card__img-wrap">
            <div className='profile-mini-card__img-wrapper'>
                <Avatar
                    isSquare={true}
                    avatar={user?.avatar}
                    width={64}
                    height={64}
                />
            </div>
            <div className="profile-mini-card__indicators">
                <Score score={user?.reviews_avg_score} withBackground={true} />
            </div>
        </div>
        <div className="profile-mini-card__content">
            {isSpecialist ? <div>
                <p className="profile-mini-card__title">
                    {user?.name} {user?.family_name}
                </p>
            </div> : <Link to={linkToUser} state={window.location.pathname}>
                <p className="profile-mini-card__title">
                    {user?.name} {user?.family_name}
                </p>
            </Link>}
            <span className="profile-mini-card__deal">
                {user?.deals_count + " "} {dealCountsText}
            </span>
            {user?.reviews_count && user?.reviews_count > 0 ?
                <Link
                    className="profile-mini-card__link-review link link--underline"
                    to={linkToReviews}
                >
                    {user?.reviews_count + " "} {reviewCountsText}
                </Link> : <span className="indicators__link-review ">{entities?.without_reviews?.value}</span>
            }
            {isSpecialist && (
                <div className="languages__wrapper">
                    <ul className="languages__list">
                        {user?.languages?.slice(0, 3)?.map((language) => (
                            <li
                                className="languages__item popover"
                                key={language?.id}
                            >
                                <span className="popover__tip">
                                    {language?.name}
                                </span>
                                <img
                                    src={language?.flag?.original_url}
                                    width="15"
                                    height="11"
                                    alt="flag"
                                />
                            </li>
                        ))}
                    </ul>
                    {user?.languages?.length > 3 && (
                        <span className="languages__add">
                            {`+${user?.languages?.length - 3}`}
                        </span>
                    )}
                </div>
            )}
            <div className="profile-mini-card-contacts">{user?.phone}</div>
            <div className="profile-mini-card-contacts">{user?.email}</div>
        </div>
    </div>
    );
};

export default UserCard;
