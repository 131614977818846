export const SERVICE_CATEGORY_ID = "service_category_id";
export const SERVICE_CATEGORY_NAME = "service_category_name";
export const SERVICE_CATEGORY_SLUG = "service_category_slug";
export const SERVICE_CATEGORY_PROMO_TEXT = "service_category_promo_text";
export const SERVICE_TYPE_ID = "service_type_id";
export const SERVICE_TYPE_NAME = "service_type_name";
export const SERVICE_TYPE_SLUG = "service_type_slug";
export const SERVICE_SUB_TYPE_IDS = "service_sub_type_ids";
export const SERVICE_SUB_TYPE_NAME = "service_sub_type_name";
export const SERVICE_SUB_TYPE_SLUG = "service_sub_type_slug";
export const CUSTOM_SERVICE_TYPE = "custom_service_type";
export const CUSTOM_SERVICE_SUB_TYPE = "custom_service_sub_type";
export const FILTER_PAGES = "filter_pages";
export const FILTER_MAX_ELEMENTS_SHOW = 10;
export const IS_MY_ADDRESS = "is_my_address";
export const IS_SPECIALIST_ADDRESS = "is_specialist_address";
export const IS_CUSTOMER_ADDRESS = "is_customer_address";
export const REPEAT_DAY = "repeat_day";
export const REPEAT_RANGE = "repeat_range";
export const IS_PRIVATE = "is_private";
export const IS_INDIVIDUAL = "is_individual";
export const IS_REMOTE = "is_remote";
export const IS_INTERESTING = "is_interesting";
export const MY_ADDRESS = "my_address";
export const SPECIALIST_ADDRESS = "specialist_address";
export const STARTED_AT = "started_at";
export const ENDED_AT = "ended_at";
export const PRICE= "price"
export const DESCRIPTION = 'description'
export const IS_PUBLISHED = 'is_published'
export const ID = 'id'
export const TITLE = 'title'
export const STATUS = 'status'
export const LANGUAGE_IDS = 'language_ids'
export const SPECIALIST_IDS = 'specialist_ids'
export const SPECIALIST_NAME = 'specialist_name'

export const DEFAULT_LATITUDE = 35.06691240997295
export const DEFAULT_LONGITUDE = 33.09222170117199

