import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import { Image } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";

const PhotoSlider: React.FC<{
    photos: Image[];
    entities?: TranslationObject;
    title?: string;
}> = ({photos, entities, title}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [translate, setTranslate] = useState(0);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [itemWidth, setItemWidth] = useState(0);

    const updateItemWidth = () => {
        if (containerRef.current) {
            const firstSlide = containerRef.current.querySelector(".slider__slide");
            if (firstSlide) {
                const computedStyle = getComputedStyle(firstSlide);
                const marginLeft = parseFloat(computedStyle.marginLeft || '0');
                const marginRight = parseFloat(computedStyle.marginRight || '0');
                setItemWidth(firstSlide.clientWidth + marginLeft + marginRight);
            }
        }
    };

    const scroll = (isRight: boolean) => {
        if (!containerRef.current || itemWidth === 0) return;

        const containerWidth = containerRef.current.clientWidth;
        const maxTranslate = (itemWidth * photos.length) - containerWidth;

        setTranslate((prev) => {
            let newTranslate = isRight ? prev + itemWidth : prev - itemWidth;
            if (newTranslate < 0) newTranslate = 0;
            if (newTranslate > maxTranslate) newTranslate = maxTranslate;
            return newTranslate;
        });
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            updateItemWidth();
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                left: translate,
                behavior: "smooth",
            });
        }

        const containerWidth = containerRef.current?.clientWidth || 0;
        const maxTranslate = (itemWidth * photos.length) - containerWidth;

        setIsAtStart(translate === 0);
        setIsAtEnd(translate >= maxTranslate);
    }, [translate, itemWidth, photos.length]);

    const showArrows = photos.length >= 4;

    return (
        <div className="slider">
            <h3 className="slider__title title title--h4">
                {entities?.specialist_details_service_photo?.value}
            </h3>
            <div className="slider__wrapper no-scrollbar" ref={containerRef}>
                {showArrows && !isAtStart && (
                    <button
                        className="slider__btn btn btn--nb go_left"
                        type="button"
                        onClick={() => scroll(false)}
                        aria-label="Prev"
                    >
                        <Icon type="arrow-right" width={9} height={18}/>
                    </button>
                )}
                {photos?.map((elem, key) => (
                    <div className="slider__slide" key={key}>
                        <picture>
                            <img
                                title={title}
                                src={elem?.original_url}
                                width="190"
                                height="190"
                                alt={title ? title : `slide-${key + 1}`}
                            />
                        </picture>
                    </div>
                ))}
                {showArrows && !isAtEnd && (
                    <button
                        className="slider__btn btn btn--nb go_right"
                        type="button"
                        onClick={() => scroll(true)}
                        aria-label="Next"
                    >
                        <Icon type="arrow-right" width={9} height={18} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PhotoSlider;
