import React from "react";
import { Link } from "react-router-dom";
import { getRoleCompanyUser } from "../../../store/user/selectors";
import { OrderSpecialistItem } from "../../../models/orderModel";
import { CurrentSpecialistsPayload } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";
import { chooseDeclension } from "../../../untils";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { getItemCreateOrder } from "../../../store/order/selectors";

interface Props {
    specialist: OrderSpecialistItem | CurrentSpecialistsPayload;
    entities?: TranslationObject;
    linkToSpecialist?: string;
}

const SpecialistServices: React.FC<Props> = ({
    specialist,
    entities,
    linkToSpecialist,
}) => {
    const servicesLength = specialist?.services?.length || 0;
    const commonMore = entities?.common_more_count?.value || "";
    const currentLanguage = getCurrentLanguage();
    const isRussian = currentLanguage === "ru";
    const servicesText = chooseDeclension(
        servicesLength - 3,
        entities?.common_services_count?.value,
        isRussian
    );
    const isAllowSelectSpec = getItemCreateOrder("isAllowSelectSpecialist");
    const moreServicesText =
        String(commonMore) +
        " " +
        String(servicesLength - 3) +
        " " +
        String(servicesText);

    return (
        <div className="prof-services">
            <ul className="prof-services__list">
                {specialist?.services?.slice(0, 3)?.map((service, key) => (
                    <li className="prof-services__item" key={service?.id}>
                        {service?.service_type?.name ||
                            service.custom_service_type}
                    </li>
                ))}
            </ul>
            {servicesLength > 3 && (
                isAllowSelectSpec ? <>
                    <Link
                        className="prof-services__link-more link link--underline"
                        to={linkToSpecialist || "/"}
                    >
                        {moreServicesText || ""}
                    </Link>
                </> : <>
                    <div className="popover popover--grey popover--specialist-link">
                        <a className="prof-services__link-more link link--underline">
                            {moreServicesText || ""}
                        </a>
                        <span className="popover__tip">
                            {entities?.order_fill_filters?.value}
                        </span>
                    </div>
                </>
            )}
            <ul className="prof-services__list">
                {specialist && (
                    <li className="prof-services__item">
                        {getRoleCompanyUser({specialist, entities})}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default SpecialistServices;
