import React, { useState } from "react";
import { OrderSpecialistItem } from "../../../../../models/orderModel";
import {Customer, OrderItem} from "../../../../../models/ordersModel";
import { ChatSpecialistCard } from "./ChatSpecialistCard";
import { ChatCustomerCard } from "./ChatCustomerCard";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ComplainSpecialistModal } from "../../ComplainSpecialistModal";
import { useSelector } from "react-redux";
import { isActiveChatSelector } from "../../../../../store/chat/chat.selectors";
import { TranslationObject } from "../../../../../models/translationModal";

interface ChatUserCardProps {
    specialist?: OrderSpecialistItem;
    customer?: Customer;
    pinOrUnpinChat?: () => unknown;
    pinnedChatStatus: boolean;
    entities?: TranslationObject;
    order?: OrderItem;
}

export const ChatUserCard: React.FC<ChatUserCardProps> = ({
    specialist,
    customer,
    pinOrUnpinChat,
    pinnedChatStatus,
    entities,
    order
}) => {
    const navigate = useNavigate();

    const isActiveChat = useSelector(isActiveChatSelector);

    const { id: orderId, currentLanguage, role } = useParams();

    const [visibleModalComplain, setVisibleModalComplain] = useState(false);

    const [modalUserId, setModalUserId] = useState<string | number | null>(
        null
    );

    const handleClickLinkToUserReviews = (userId?: string | number) => {
        if (!currentLanguage || !role || !userId) return;
        navigate(
            generatePath(`/:currentLanguage/:role/:id/reviews`, {
                currentLanguage,
                role,
                id: String(userId),
            })
        );
    };

    const handleClickOpenModalComplain = (userId?: string | number) => {
        if (!isActiveChat) return;

        if (userId) {
            setVisibleModalComplain(true);
            setModalUserId(userId);
        }
    };

    return (
        <>
            {(role === "specialist" || role === "customer") && (
                <ComplainSpecialistModal
                    userRole={role}
                    visible={visibleModalComplain}
                    onClose={() => {
                        setVisibleModalComplain(false);
                        setModalUserId(null);
                    }}
                    userId={modalUserId}
                    orderId={orderId}
                    entities={entities}
                />
            )}
            {specialist && (
                <ChatSpecialistCard
                    handleLinkReviews={handleClickLinkToUserReviews}
                    handleOpenModalComplain={handleClickOpenModalComplain}
                    pinnedChatStatus={pinnedChatStatus}
                    specialist={specialist}
                    pinOrUnpinChat={pinOrUnpinChat}
                    entities={entities}
                    order={order}
                />
            )}
            {customer && (
                <ChatCustomerCard
                    entities={entities}
                    handleLinkReviews={handleClickLinkToUserReviews}
                    handleOpenModalComplain={handleClickOpenModalComplain}
                    customer={customer}
                    pinnedChatStatus={pinnedChatStatus}
                    pinOrUnpinChat={pinOrUnpinChat}
                />
            )}
        </>
    );
};
