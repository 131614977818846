import React, { useState } from "react";
import Icon from "../Icon";
import {IS_CUSTOMER_ADDRESS, IS_MY_ADDRESS, IS_REMOTE} from "../../store/order/constant";
import classNames from "classnames";
import { TranslationObject } from "../../models/translationModal";
import { Service, ServiceSubType } from "../../models/userModels";
import PhotoSlider from "./PhotoSlider";

const ServiceTypeCard: React.FC<{
    serviceType: Service;
    entities?: TranslationObject;
    serviceTypeTranslate?: Service | null;
}> = ({ serviceType, entities, serviceTypeTranslate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const points = {
        [IS_REMOTE]: `${entities?.common_remotely?.value}`,
        [IS_MY_ADDRESS]: `${entities?.common_specialist_adress?.value}`,
        [IS_CUSTOMER_ADDRESS]: `${entities?.common_customer_adress?.value}`,
    };
    const title = serviceType?.service_type?.name + ': ' + serviceType?.service_sub_types?.map(service => service?.service_sub_type?.name).filter(name => name).join(", ");

    return (
        <div
            className={classNames(
                "specialist-page__dropdown grey-card dropdown",
                {
                    "is-open": isOpen,
                }
            )}
        >
            <div className="dropdown__header">
                <h3 className="dropdown__title title title--f32">
                    {serviceType?.service_type?.name ||
                        serviceType?.custom_service_type}
                </h3>
                <button
                    className="dropdown__btn btn btn--transparent btn--nb"
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Icon
                        type={isOpen ? "arrow_up" : "arrow_down"}
                        width={20}
                        height={20}
                    />
                </button>
            </div>
            <div className="dropdown__content">
                <p className="dropdown__item dropdown__sub subtitle subtitle--sm"></p>
                <h4 className="dropdown__item title title--h4">
                    {entities?.common_meeting_place?.value}
                </h4>
                <div className="dropdown__item text">
                    {(serviceType?.is_remote) && (
                        <p>
                            <b>{points["is_remote"]}</b>
                        </p>
                    )}
                    {(serviceType?.specialist_address) && (
                        <p>
                            <b>{points["is_my_address"]}: {serviceType?.specialist_address?.value}</b>
                        </p>
                    )}
                    {(serviceType?.customer_address) && (
                        <p>
                            <b>{points["is_customer_address"]}: {serviceType?.customer_address?.value}, {entities?.in_distance_of?.value.replace(":radius", serviceType?.customer_address?.radius)}</b>
                        </p>
                    )}
                </div>
                <h4 className="dropdown__item title title--h4">
                    {entities?.list_of_services_title?.value}
                </h4>
                <p className="dropdown__item text color-grey">
                    {serviceTypeTranslate?.description ||
                        serviceType?.description}
                </p>
                <div className="dropdown__item details subtypes">
                    {serviceType?.service_sub_types?.map(
                        (serviceSubType: ServiceSubType, key: number) => (
                            <dl key={key + serviceSubType?.id}>
                                <dt>
                                    <h5 class="dropdown__item title title--h5">
                                        {serviceSubType?.service_sub_type?.name ??
                                        serviceSubType?.custom_service_sub_type}</h5>
                                    {(serviceTypeTranslate?.service_sub_types?.find(
                                        (el) => el?.id === serviceSubType?.id
                                    )?.description || serviceSubType?.description) && (
                                        <p className="details__text text color-grey">
                                            {serviceTypeTranslate?.service_sub_types?.find(
                                                (el) => el?.id === serviceSubType?.id
                                            )?.description || serviceSubType?.description}
                                        </p>
                                    )}
                                </dt>
                                <dd>
                                    <h5 className="dropdown__item title title--h5">
                                        {serviceTypeTranslate?.service_sub_types?.find(
                                            (el) => el?.id === serviceSubType?.id
                                        )?.price || serviceSubType?.price}
                                    </h5>
                                </dd>
                            </dl>
                        )
                    )}
                </div>
                {serviceType?.photos?.length > 0 && (
                    <PhotoSlider
                        photos={serviceType?.photos}
                        entities={entities}
                        title={title}
                    />
                )}
            </div>
        </div>
    );
};

export default ServiceTypeCard;
