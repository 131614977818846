import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "../Form/Input";
import { getPath } from "../../store/directories/selectors";
import { useConfirmationsMutation } from "../../store/auth/auth.api";
import { endpoints } from "../../store/user/user.api";
import { useNavigate } from "react-router-dom";
import Form from "../Form/Form";
import { useActions } from "../../hooks/actions";
import { getUrlAfterAuthorization } from "../../store/user/selectors";
import { ComponentProps } from "../../models/models";
import { setTokenCookie } from "../../store/auth/selectors";
import { getItemStore } from "../../untils";
import { getCookie, deleteCookie } from "../../untils/cookies";

interface Props extends ComponentProps {
    phone?: string | null;
    changeStep: any;
    data?: any;
    resendCode: any;
    isAuth?: boolean;
    email?: string | null;
    handleReturn: () => void;
    resendError?: any;
    successStep?: string;
}

const Index: React.FC<Props> = ({
    entities,
    phone,
    changeStep,
    data,
    resendCode,
    isAuth,
    email,
    handleReturn,
    currentLanguage,
    resendError,
    successStep,
}) => {
    const login_phone_confirmation_sent_message =
        phone &&
        entities?.login_phone_confirmation_sent_message?.value?.replace(
            ":phone",
            String(phone)
        );
    const nextUrl =
        getUrlAfterAuthorization() ||
        `/${currentLanguage}/${getPath()[1]}/lk/orders`;

    const registration_email_confirmation_text =
        email &&
        entities?.registration_email_confirmation_text?.value?.replace(
            ":email",
            String(email)
        );
    const { updateUserField } = useActions();
    const [update, { isSuccess, error: errorConfirm, data: responseConfirm }] =
        useConfirmationsMutation();
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [timer, setTimer] = useState<number>(0);
    const isDisabled = !token || String(token).length < 4;
    const isEmail = !!email;
    const error = errorConfirm ?? resendError;
    const [updateProfile, { data: profile }] = endpoints.getProfile.useLazyQuery();
    const urlIncludeSpecialist = document.location.href.includes('/specialist/');

    const onSuccess = async () => {
        const access_token = responseConfirm?.payload?.access_token;
        const expires_in = responseConfirm?.payload?.expires_in;
        const draft_id = getCookie('draft');

        if (access_token) {
            setTokenCookie({ access_token, expires_in });
            if (!isEmail && access_token) {
                updateUserField({
                    name: "tkn",
                    value: access_token,
                });
            }
            await updateProfile();
        }

        if (draft_id) {
            deleteCookie("draft");
            return;
        }

        if (isAuth) {
            const rememberUrl = localStorage.getItem('remember_url');
            if (rememberUrl && urlIncludeSpecialist) {
                navigate(rememberUrl);
            } else {
                navigate(nextUrl);
            }

            return;
        }

        if (isEmail && successStep) {
            changeStep(successStep);
            return;
        }

        changeStep(isEmail ? "two" : "three");
    };

    useEffect(() => {
        if (profile?.is_draft) {
            changeStep(profile?.company ? "three" : "four");
        }
    }, [profile]);

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (timer < 1) return;

        const timerRef = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);

        return () => clearInterval(timerRef);
    }, [timer]);

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        const draft_id = getCookie('draft');

        if (token && data?.payload?.id) {
            update({
                id: data?.payload?.id,
                token,
                user_id: draft_id || null
            });
        }
    };

    const handleChangeToken = (value: string) => {
        if (value && String(value).length > 4) {
            return setToken(String(value).slice(0, 4));
        }
        setToken(value);
    };

    useEffect(() => {
        if (data?.payload?.resend_delay_in_seconds) {
            setTimer(data?.payload?.resend_delay_in_seconds);
        }
    }, [data]);

    const handleResentCode = () => {
        resendCode();
    };

    return (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {isEmail
                        ? entities?.registration_email_confirmation?.value
                        : entities?.login_phone_confirmation_title?.value}
                </h1>
                <p className="registration-form__text">
                    {isEmail
                        ? registration_email_confirmation_text
                        : login_phone_confirmation_sent_message}
                </p>
            </div>
            <div className="form">
                <Form
                    entities={entities}
                    onSubmit={onSubmit}
                    error={error}
                    isSuccess={isSuccess}
                >
                    {(invalidFields, error) => (
                        <>
                            <div className="form__row">
                                <Input
                                    type="number"
                                    name="token"
                                    onChange={handleChangeToken}
                                    placeholder={
                                        isEmail
                                            ? entities
                                                  ?.registration_email_confirmation_hint
                                                  ?.value ?? ""
                                            : entities
                                                  ?.login_phone_confirmation_input
                                                  ?.value ?? ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                    value={token}
                                />
                                {timer > 0 ? (
                                    <p className="form__note">
                                        {entities?.login_new_phone_confirmation?.value.replace(":sec", "")}
                                        <span>{`00:${String(timer).length < 2 ? "0" + timer : timer}`}</span>
                                    </p>
                                ) : (
                                    <p className="form__note" onClick={handleResentCode}>
                                        <a className="link link--hover-underline">
                                            {entities?.code_resend?.value}
                                        </a>
                                    </p>
                                )}
                            </div>
                            <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                                <div className="btn btn--transparent" onClick={handleReturn}>
                                    {entities?.login_return_button?.value}
                                </div>
                                <button
                                    className="btn btn--transparent btn--bg-blue"
                                    type="submit"
                                    disabled={isDisabled}
                                >{isAuth
                                    ? entities?.login_phone_confirmation_enter_button?.value
                                    : entities?.login_continue_button?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};
export default Index;
