import React, { FC } from "react";

interface Props {
    title: string;
    count: number;
    onClick: () => void;
}

const MobileFilterTitle: FC<Props> = ({ title, count, onClick }) => {
    return (
        <button
            className="filter__btn-subtitle"
            type="button"
            onClick={onClick}
        >
            <div className="title">
                <span>{title}</span>
                <span className="filter__star">*</span>
            </div>
            {count > 0 && <span className="filter__msg-count">{count}</span>}
        </button>
    );
};

export default MobileFilterTitle;
